import { FetchDataItem } from './FetchDataItem';
import { Layout } from './Layout';

var React = require('react');

export class FetchData extends React.Component {

    static renderForecastsTable(l, messages) {
        if (messages.length <= 0)
            return (
                <div className="message">
                    <div className="noMessages">
                        You have not saved any messages!
                    </div>
                </div>
            );
        
        return (
            <div id="messageArea">                
                {messages.map(message =>                     
                    <FetchDataItem message={message} parentLayout={l} key={message.id} />
                )}
            </div>
        );
    }

    onDeleteAllClick = (e) => {
        Layout.DeleteAll(this.props.parentLayout);
    }

    displayName = FetchData.name

    render() {
        let contents = this.props.loading
            ? <div className="message"><div className="noMessages">Loading...</div></div>
            : FetchData.renderForecastsTable(this.props.parentLayout, this.props.messages);

        return (
            <div>
                <h2>Your messages</h2>
                <div className="mainActions">
                    <a href="#" className="deleteAll" onClick={this.onDeleteAllClick}>delete all</a>
                    {/*| <a href='services.aspx?action=share&sharedKey=bbuiyfpt4qpc51x5ic0h0z2u&sharedIPAddress=1430882932' className="shareLink">copy share link to clipboard</a>*/}
                </div>
                <div className="clear1h" />
                {contents}
            </div>
        );
    }
}
