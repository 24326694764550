import { Layout } from './Layout';
import { isMobile } from 'react-device-detect';
import AdSense from 'react-adsense-ad';

var React = require('react');

export class Form extends React.Component {
    displayName = Form.name

    constructor(props) {
        super(props);
        this.state = {
            currentSystemDate: null,
            userMessage: "",
            userPassword: "",
            fileUpload: "",
            fileStream: null
        };
    }

    onFileChange = (e) => {
        var file = e.target.files[0];
        this.setState({ fileStream: file, fileUpload: file.name });
    }

    onFileTextClick = (e) => {
        this.inputFileElement.click();
    }

    mySubmitHandler = (event) => {
        event.preventDefault();

        const that = this;
        var data = new FormData()
        data.append('currentSystemDate', Date().toLocaleString());
        data.append('userMessage', this.state.userMessage);
        data.append('userPassword', this.state.userPassword);
        data.append('fileUpload', this.state.fileUpload);
        data.append('fileStream', this.state.fileStream);

        fetch('https://ipshareapi.azurewebsites.net/api/Add', {
            method: 'POST',
            body: data
        }).then(function (response) {
            Layout.ReloadMessages(that.props.parentLayout);
        });

        // clear form!
        this.setState({
            currentSystemDate: null,
            userMessage: "",
            userPassword: "",
            fileUpload: "",
            fileStream: null
        });
    }

    render() {
        return (
            <div>
                <div id="userShareBox">
                    <div id="userShareBoxMain">
                        <form id="postMessageForm" method="POST" encType="multipart/form-data" onSubmit={this.mySubmitHandler}>
                            <textarea id="userMessage" name="data" placeholder="Message" value={this.state.userMessage} onChange={e => this.setState({ userMessage: e.target.value })} />
                            <div className="clear10h" />
                            <div id="userShareBoxMainSubmit">
                                <input type="submit" id="userSubmit" defaultValue="Save" />
                                <img src="/Images/ajaxLoader.png" alt="Loading..." id="messageSubmitLoader" />
                            </div>
                            <div id="userShareBoxMainFile">
                                <label htmlFor="fileUpload">File upload</label>
                                <input type="file" id="fileUpload" name="fileUpload" onChange={this.onFileChange} ref={input => this.inputFileElement = input} />
                                <input type="text" id="fileSelect" name="fileSelect" value={this.state.fileUpload} readOnly="readonly" onClick={this.onFileTextClick} />
                                &nbsp;Max. 25MB
                        </div>
                            <div id="userShareBoxMainPassword">
                                <label htmlFor="userPassword">Password protect</label>
                                <input type="text" id="userPassword" name="password" maxLength="10" value={this.state.userPassword} onChange={e => this.setState({ userPassword: e.target.value })} />
                                &nbsp;up to 10 chars
                        </div>
                        </form>
                    </div>

                    <div id="mainBanner">
                        {!isMobile ||
                            <AdSense.Google
                                client='ca-pub-4604166943078261'
                                slot='2335065873'
                                style={{ width: 300, height: 250 }}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}
