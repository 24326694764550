import { Layout } from './Layout';
var React = require('react');

export class FetchDataItem extends React.Component {
    displayName = FetchDataItem.name

    constructor(props) {
        super(props);
        this.state = { password: "" };

        Layout.ReloadMessages(this);
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter' && this.state.password !== "") {            
            this.props.parentLayout.addPassword(this.state.password);
        }
    }

    render() {
        let message = this.props.message;
        if (message.password == "True")
            return (
                <div className="message">
                    <div className="messageDateTime">
                        {message.time}
                    </div>
                    <div className="messagePasswordProtected">
                        <label htmlFor="userPassword">
                            Password Protected</label>
                        <input type="text" name={"passwordProtectedField_" + message.id} className="addPassword" value={this.state.password} onChange={e => this.setState({ password: e.target.value })} onKeyPress={this.handleKeyPress} maxLength="10" />
                        <img src="/Images/ajaxLoader.png" alt="Loading..." className="passwordMessageLoader" />
                    </div>
                    <div className="messageActions">
                        <div className="clear5h" />
                    </div>
                    <div className="clear1h" />
                </div>
            );

        return (
            <div className="message">
                <div className="messageDateTime">
                    {message.time}
                </div>
                <div className="messageText">
                    {message.filename != "" &&
                        <a target="_blank" href={"https://ipshareapi.azurewebsites.net/api/Download?id=" + message.id} className="download">{message.filename}</a>
                    }
                    {message.data.replace(/ /g, "\u00a0").split('\n').map((item, key) => {
                        return <span key={key}>{item}<br /></span>
                    })}
                </div>
                <div className="messageActions">
                    <a href={"#" + message.id} className="deleteItem" onClick={(event) => {
                        event.preventDefault();
                        Layout.DeleteById(this.props.parentLayout, message.id);
                    }}>Delete</a>
                    <img src="/Images/ajaxLoader.png" alt="Loading..." className="deleteMessageLoader" />
                    <div className="clear5h" />
                </div>
                <div className="clear1h" />
            </div>
        );
    }
}
