var React = require('react');

export class Footer extends React.Component {
    displayName = Footer.name
    
    render() {
        return (            
            <footer>
                <div className="widthFull marginAuto">
                    <div id="footer">
                        Idea and developed by <a href="http://www.spyriadis.net" title="Spyriadis Network">spyriadis network</a>, designed by <a href="http://www.stereotropism.com" title="stereotropism">stereotropism</a>
                    </div>
                </div>
            </footer>
    );
    }
}
