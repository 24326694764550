import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Form } from './Form';
import { FetchData } from './FetchData';
import { Footer } from './Footer';

const ls = require('localstorage-ttl');

export class Layout extends React.Component {

    static ReloadMessages(l) {
        fetch('https://ipshareapi.azurewebsites.net/api/List', {
            method: 'POST',
            body: JSON.stringify({
                passwords: l.state.passwords,
                shareCookie: ""
            })
        })
            .then(response => response.json())
            .then(data => {
                l.setState({ messages: data.result, loading: false });
            });
    }
    static DeleteById(l, id) {
        fetch('https://ipshareapi.azurewebsites.net/api/Delete?deleteID=' + id, {
            method: 'GET'
        }).then(function (response) {
            Layout.ReloadMessages(l);
        });     
    }
    static DeleteAll(l) {
        fetch('https://ipshareapi.azurewebsites.net/api/DeleteAll', {
            method: 'GET'
        }).then(function (response) {
            Layout.ReloadMessages(l);
        });
    }

    displayName = Layout.name

    addPassword = (password) => {        
        let passwords = this.state.passwords;
        passwords.push(password);        
        this.setState({ passwords: passwords });
        ls.set("passwords", passwords, 30 * 60 * 1000); // 30 mins
        Layout.ReloadMessages(this);
    };

    constructor(props) {
        super(props);

        this.state = { messages: [], passwords: ls.get("passwords"), loading: true };

        Layout.ReloadMessages(this);
    }    
    
    render() {
        return (
            <div>
                <NavMenu />
                <div className="widthFull marginAuto">
                    <Form messages={this.state.messages} parentLayout={this} />
                    <FetchData messages={this.state.messages} loading={this.state.loading} parentLayout={this} />
                    <div className="clear30h" />
                    <div role="main">
                        <div className="details">
                            <p>
                                This page allows you to share a clipboard between all your computers or mobile devices
                                on your local network. You could paste on the large text box, on the top of the
                                page, anything up to 4000 characters long and then by entering this page from any
                                other device on your local network you will find your message below on a list.</p>
                            <p>
                                In case you are on a large network (e.g. your office) there is an option to password
                                protect your message so that no one without the password could see it.</p>
                            <p>
                                You can share a clipboard between two different local area networks by using the "share link".
                                Simply send your share link to your friend and he/she will get automatically access to your saved messages!
                            </p>
                            <p>
                                All messages will be deleted after 24 hours of inactivity.
                                This is not a permanent storage location so do not use it to store sensitive information.</p>
                            <p>
                                <strong>NEW!</strong>Now you can also share files up to 25MB each! :)
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
